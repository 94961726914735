import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';

import { useParams, Redirect } from 'react-router-dom';
import loaderSvg from '../../assets/img/loader.svg';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
import axiosInstance from '../../axiosInstance';

import logo from '../../assets/img/logo.png';
import '../../assets/css/App.css';
import '../../assets/scss/global.scss';

import { decode } from 'js-base64';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import PaymentModal from 'components/Card/PaymentModal';
import { STATES_DATA } from 'states';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={4}>
          <Typography component={'span'} variant={'body2'}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default function ChequeMethod({ location }) {
  const [value, setValue] = React.useState(0);
  const [refund, setRefund] = React.useState(null);
  const [refundName, setRefundName] = React.useState(null);
  const [paypalid, setPaypalid] = React.useState(null);

  const [msg, setMsg] = React.useState(null);
  const [errormsg, setErrormsg] = React.useState(null);
  const [loaded, setloaded] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [redirects, setRedirects] = React.useState(false);
  const [charity, setCharity] = React.useState(false);
  const [allowAddressUpdate, setAllowAddressUpdate] = React.useState(false);
  const params = useParams();
  const via = new URLSearchParams(location.search).get('via');

  const [address, setAddress] = React.useState({
    refund_to_address_line1: '',
    refund_to_address_line2: '',
    refund_to_city: '',
    refund_to_state: '',
    refund_to_zip: '',
    first_name: '',
    name: '',
    is_updated: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setAddress({ ...address, is_updated: true, [name]: value });
  };

  const handleCancel = () => {
    setCharity(false);
    setOpen3(true);
  };

  const handleDonation = () => {
    setCharity(true);
    setOpen3(false);
  };

  const handleClickPopup = () => {
    setOpen3(true);
  };

  // function to handle submit check button.

  const handleCheck = () => {
    setloaded(true);

    axiosInstance
      .post('/add-check', {
        address,
      })

      .then((res) => {
        setloaded(false);
        setMsg(res && res.data && res.data.msg);
        setTimeout(() => {
          setErrormsg(null);
          setMsg(null);

          localStorage.removeItem('otpForPayment');
          setRedirects(true);
        }, 4000);
      })
      .catch((err) => {
        setloaded(false);
        setErrormsg(err.response.data.msg);
        setTimeout(() => {
          setErrormsg(null);
          setMsg(null);
        }, 4000);
        console.log(err);
      });
  };
  const getRefundDetails = async () => {
    try {
      const result = await axiosInstance.post('/get_refund_details');
      setRefund(result.data.data);
      setAllowAddressUpdate(result.data.data.allow_address_update);
      setAddress({
        refund_to_address_line1: result.data.data.refund_to_address_line1 ?? '',
        refund_to_address_line2: result.data.data.refund_to_address_line2 ?? '',
        refund_to_city: result.data.data.refund_to_city ?? '',
        refund_to_state: result.data.data.refund_to_state || 'AL',
        refund_to_zip: result.data.data.refund_to_zip ?? '',
        name:
          (result.data.data.first_name ?? '') +
          ' ' +
          (result.data.data.last_name ?? ''),
        is_updated: false,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRefundDetails();
  }, []);
  const handleClose3 = () => {
    //setOpen3(false);
    //setCharity(true);
    //setOpen3(false);
  };
  if (redirects) {
    return <Redirect to={`/client/thankyou`} />;
  }

  return (
    <div className='submit-mail-wrapper'>
      <PaymentModal
        charity={charity}
        handleCancel={handleCancel}
        open3={open3}
        handleClose={handleClose3}
        handleDonation={handleDonation}
      />
      <header className='s-header'>
        <div className='container'>
          <div className='s-header_inner'>
            <div className='inner-brand'>
              <img src={logo} alt='' />
            </div>
            <div className='site-links'>
              <ul className='n-links'>
                {/* <li>
                  <Link to='/' className='n-link'>
                    FAQ
                  </Link>
                </li> */}
                <li>
                  <a
                    href='https://www.clientpaydirect.com/contact'
                    className='n-link'
                    target='_blank'>
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      {/*<!--<header className="s-header">
                <div class="container">
                    <div class="s-header_inner">
                        <Link to="/" class="brand">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                </div>
            </header>-->*/}
      <div className='main-content p-b-30'>
        <div className='container'>
          {loaded && (
            <div
              className='loaders'
              style={{
                alignSelf: 'center',
                justifySelf: 'center',
                marginLeft: '550px',
              }}>
              <img src={loaderSvg} />
            </div>
          )}
          <div className='payment'>
            <div className='payment-left'></div>
            <div className='payment-right'>
              <div className='payment-right_inner'>
                <h2 className='page-heading'>Refund Via Check</h2>
                {/*<!--<p className="page-desc">Please confirm your address below and click 'Submit' to receive your funds in 7 - 10 business days</p>-->*/}

                <p className='page-desc'>
                  {' '}
                  <b>{refundName}</b>
                </p>
                <p className='page-desc'>
                  Please click <b>'submit'</b> and you will be mailed a check to
                  your below address on file, in 7 days.
                </p>
                {msg && <p style={{ color: 'green' }}>{msg}</p>}
                {errormsg && <p style={{ color: 'red' }}>{errormsg}</p>}
                {!allowAddressUpdate && refund && (
                  <div>
                    <p style={{ marginBottom: '7px', fontWeight: 'bold' }}>
                      Address on file:
                    </p>
                    <p style={{ marginBottom: '7px' }} className='page-desc'>
                      {refund.refund_to_fullname}
                    </p>
                    <p style={{ marginBottom: '7px' }} className='page-desc'>
                      {refund.refund_to_address_line1},{' '}
                      {refund.refund_to_address_line2}
                    </p>
                    <p style={{ marginBottom: '7px' }} className='page-desc'>
                      {refund.refund_to_city}, {refund.refund_to_state},{' '}
                      {refund.refund_to_zip}{' '}
                    </p>
                  </div>
                )}

                <div className='tab-section'>
                  <div className='payment-form'></div>
                  <div className='payment-form'>
                    {allowAddressUpdate && (
                      <form onSubmit={handleCheck}>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'>Name</label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                name='refund_to_address_line1'
                                value={address.name}
                                disabled
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'>Address Line 1</label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                name='refund_to_address_line1'
                                onChange={handleInputChange}
                                value={address.refund_to_address_line1}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'>Address Line 2</label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                name='refund_to_address_line2'
                                onChange={handleInputChange}
                                value={address.refund_to_address_line2}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w33'>
                            <label className='form-label'>City</label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                name='refund_to_city'
                                onChange={handleInputChange}
                                value={address.refund_to_city}
                              />
                            </div>
                          </div>
                          <div className='w33'>
                            <label className='form-label'>State</label>
                            <div className='form-field'>
                              {/* <input
                                type='text'
                                className='form-control'
                                name='refund_to_state'
                                onChange={handleInputChange}
                                value={address.refund_to_state}
                              /> */}

                              <select
                                name='refund_to_state'
                                onChange={handleInputChange}
                                value={address.refund_to_state}
                                className='form-control'>
                                {STATES_DATA.map((state, index) => (
                                  <option
                                    key={index}
                                    value={state.abbreviation}>
                                    {state.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='w33'>
                            <label className='form-label'>Zip</label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                name='refund_to_zip'
                                onChange={handleInputChange}
                                value={address.refund_to_zip}
                                s
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                    <div className='form-row'>
                      <div className='w50'>
                        <div className='form-btn text-center'>
                          <Button
                            variant='contained'
                            className='form-save'
                            onClick={handleCheck}>
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                    <div className='mt-2'>
                      <button
                        variant='contained'
                        className='btn-green-outline'
                        onClick={handleClickPopup}>
                        <ArrowBackIosIcon />
                        Select Different Payment Option
                      </button>
                    </div>
                  </div>
                  {/*<!--<TabPanel value={value} index={3}>Cheque/DD</TabPanel>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
