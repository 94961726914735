import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

import './payment-method.scss';

import { useParams, Redirect } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import Dialog from '@material-ui/core/Dialog';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import tickmethod from '../../assets/img/tick-method.svg';
import charityIcon from '../../assets/img/charity-icon.svg';
import { API_BASE_URL, API_BASE_IMG_URL } from '../../config';
import { loadMoov } from '@moovio/moov-js';
import axiosInstance from '../../axiosInstance';

import { Base64 } from 'js-base64';
// or if you prefer no Base64 namespace
import { encode, decode } from 'js-base64';

import logo from '../../assets/img/logo.png';
import PaymentModal from 'components/Card/PaymentModal';
import { Lock } from '@material-ui/icons';
import { toast } from 'react-toastify';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={4}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};
const handleClose3 = () => {
  //setOpen3(false);
  //setCharity(true);
  //setOpen3(false);
};
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));
export default function PaymentMethod() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [refundEmail, setRefundEmail] = useState(null);
  const [phone, setPhone] = useState(null);
  const [refundamount, setRefundamount] = useState(null);
  const [refundName, setRefundName] = useState(null);
  const [paypalid, setPaypalid] = useState(null);
  const [venmoid, setVenmoid] = useState(null);
  const [achMethod, setAchMethod] = useState(null);

  const [name, setName] = useState(null);
  const [routingNumber, setRoutingNumber] = useState(null);
  const [accountNumbers, setAccountNumbers] = useState({
    accountNumber: '',
    confirmAccountNumber: '',
  });

  const [contactInfo, setContactInfo] = useState(null);

  const [loading, setLoading] = useState(false);

  const [msg, setMsg] = useState(null);
  const [errormsg, setErrormsg] = useState(null);
  const [redirect, setRedirect] = useState(false);
  const [paymentdetailid, setPaymentdetailid] = useState(null);
  const [bankName, setBankName] = useState(null);
  const [open3, setOpen3] = useState(false);
  const [bankAccountType, setBankAccountType] = useState('');
  const [achType, setAchType] = useState('');

  const [charity, setCharity] = useState(false);

  const params = useParams();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /***Handle bank name textbox  value*/
  const handleBankName = (event) => {
    setBankName(event.target.value);
  };

  /***Handle name textbox  value*/
  const handleName = (event) => {
    setName(event.target.value);
  };

  const handelAchType = (event) => {
    setAchType(event.target.value);
  };

  const handleBankAccountType = (event) => {
    setBankAccountType(event.target.value);
  };

  /***Handle routing number textbox  value*/
  const handleRoutingNumber = (event) => {
    setRoutingNumber(event.target.value);
  };

  /***Handle account number textbox  value*/
  const handleAccountNumber = (event) => {
    setAccountNumbers({
      ...accountNumbers,
      [event.target.name]: event.target.value,
    });
  };

  /***Handle Paypal id textbox  value*/
  const handlePaypalid = (event) => {
    setPaypalid(event.target.value);
  };

  /**** Handle Venmo id textbox value */
  const handleVenmoid = (event) => {
    setVenmoid(event.target.value);
  };

  const handleClickPopup = () => {
    setOpen3(true);
  };

  const handleCancel = () => {
    setCharity(false);
    setOpen3(true);
  };

  const handleDonation = () => {
    setCharity(true);
    setOpen3(false);
  };

  /**handle direct payment */
  const directpayment = async (e) => {
    e.preventDefault();
    if (loading === true) return;
    if (refundName == null) {
      setErrormsg('Error Fetching Refund Name');
    } else if (routingNumber == null) {
      setErrormsg('Please enter routing number.');
    } else if (
      accountNumbers.accountNumber !== accountNumbers.confirmAccountNumber
    ) {
      setErrormsg(
        'Bank account number entered is not matching. Please review and re-enter. ',
      );
    } else if (!contactInfo) {
      setErrormsg('Please enter email or phone number.');
    } else {
      if (achMethod === 'default' && !bankName) {
        setErrormsg('Please enter bank name.');
        return;
      }

      if (achMethod === 'moov' && !bankAccountType) {
        setErrormsg('Please select bank account type.');
        return;
      }

      setLoading(true);

      setErrormsg('');
      const defaultAchMethod = async () => {
        try {
          axiosInstance
            .post('/update-refund-for-ach', {
              account_number: accountNumbers.accountNumber,
              routing_number: routingNumber,
              bankname: bankName,
            })

            .then((res) => {
              setPaymentdetailid(
                res &&
                  res.data &&
                  res.data.resultdata &&
                  res.data.resultdata.id,
              );
              setMsg(res && res.data && res.data.msg);
              setRedirect(true);
            })
            .catch((err) => {
              setErrormsg(err.response.data?.message);

              console.log(err);
            });

          setLoading(false);
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      };

      const moovPaymentMethod = async () => {
        try {
          const { token, accountID } = (
            await axiosInstance.post('/create-moov-account-refundee', {
              contactInfo,
            })
          ).data;

          const moov = await loadMoov(token);
          const bankAccount = {
            holderName: refundName,
            holderType: 'individual',
            accountNumber: accountNumbers.accountNumber,
            bankAccountType: bankAccountType,
            routingNumber: routingNumber,
          };

          let response;

          try {
            response = await moov.accounts.bankAccounts.link({
              accountID: accountID,
              bankAccount,
            });
          } catch (err) {
            console.log(err);
            toast.error(err.error);
            setLoading(false);
            return;
          }

          await axiosInstance.post('/create-transfer-refundee', {
            toPaymentMethod: response?.bankAccountID,
            transferType: achType,
          });

          setRedirect(true);
        } catch (err) {
          console.log(err);
        }
      };

      if (achMethod === 'default') {
        return defaultAchMethod();
      }
      moovPaymentMethod();
    }
  };

  /**** handle venmo payouts and add venmo id  */
  const handleVenmo = () => {};

  /**** handle paypal payouts */

  const handlePaypal = () => {};

  useEffect(() => {
    axiosInstance
      .get('/refund-details')
      .then((response) => {
        return response;
      })
      .then((res) => {
        let refund_email = res.data.refundData.email;

        let phone = res.data.refundData.phone;

        let refund_amount = res.data.refundData.refund_amount;

        let refundName =
          res.data.refundData.first_name + ' ' + res.data.refundData.last_name;

        setAchMethod(res.data.method);

        setRefundEmail(refund_email);
        setPhone(phone);
        setRefundamount(refund_amount);
        setRefundName(refundName);
        setContactInfo(refund_email || phone.replace('+1', ''));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const slug = params && params.id;
  return (
    <div
      className='submit-mail-wrapper'
      style={{ overflow: 'scroll', height: '100vh' }}>
      {/*<!--<header className="s-header">
                <div className="container">
                    <div className="s-header_inner">
                        <Link to="/" className="brand">
                            <img src={logo} alt="" />
                        </Link>
                    </div>
                </div>
    </header>-->*/}
      {redirect && <Redirect to={'/client/thankyou/' + slug} />}
      <PaymentModal
        charity={charity}
        handleCancel={handleCancel}
        open3={open3}
        handleDonation={handleDonation}
      />

      <header className='s-header'>
        <div className='container'>
          <div className='s-header_inner'>
            <div className='inner-brand'>
              <img src={logo} alt='' />
            </div>
            <div className='site-links'>
              <ul className='n-links'>
                {/* <li>
                  <Link to='/' className='n-link'>
                    FAQ
                  </Link>
                </li> */}
                <li>
                  <a
                    href='https://www.clientpaydirect.com/contact'
                    className='n-link'
                    target='_blank'>
                    Contact us
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {/* <p><a href="javascript:void(0)" style={{ "margin-left": "100px" }} onClick={handleClickPopup} > Select Different Payment Option </a></p> */}
      </header>
      <div className='main-content p-b-30'>
        <div className='container'>
          <div className='payment'>
            <div className='payment-lefts'></div>
            <div className='payment-right'>
              <div className='payment-right_inner'>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <h2 className='page-heading'>Refund Via Direct Deposit</h2>{' '}
                  <Lock className='page-heading' />{' '}
                </div>
                <p className='page-desc' style={{ margin: 0 }}>
                  Please enter your account details below. Your information is
                  encrypted and secure. You will receive your refund in 3-5
                  business days.
                </p>
                {msg && <p style={{ color: 'green' }}>{msg}</p>}
                {errormsg && <p style={{ color: 'red' }}>{errormsg}</p>}
                <div className='tab-section'>
                  {/* <AppBar position="static">
                                        <Tabs value={value} onChange={handleChange} aria-label="payment tabs">
                                            
                                            <Tab label="Direct Payment" {...a11yProps(0)} />
                                            <Tab label="PayPal" {...a11yProps(1)} />
                                            <Tab label="Venmo" {...a11yProps(2)} />

                                            
                                        </Tabs>
                                    </AppBar> */}

                  <TabPanel value={value} index={1}>
                    {' '}
                    <div className='payment-form'>
                      <form noValidate>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'>
                              Paypal Payer Id
                            </label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                name='paypalid'
                                onChange={handlePaypalid}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'>Email Id</label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                value={refundEmail}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'>Phone</label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                value={phone}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w50'>
                            <div className='form-btn'>
                              <Button
                                variant='contained'
                                className='form-save'
                                onClick={handlePaypal}>
                                Save
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <div className='payment-form'>
                      <div className='form-row'>
                        <div className='w100'>
                          <label className='form-label'>
                            Venmo Payer Id(Mobile Number Registered with Venmo)
                          </label>
                          <div className='form-field'>
                            <input
                              type='text'
                              className='form-control'
                              name='venmoid'
                              onChange={handleVenmoid}
                            />
                          </div>
                        </div>
                      </div>
                      <div className='form-row'>
                        <div className='w50'>
                          <div className='form-btn'>
                            <Button
                              variant='contained'
                              className='form-save'
                              onClick={handleVenmo}>
                              Save
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel value={value} index={0}>
                    <div className='payment-form'>
                      <form onSubmit={directpayment}>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'>Name</label>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                value={refundName ?? ''}
                                disabled
                              />
                            </div>
                          </div>
                        </div>

                        <div className='form-row'>
                          <div className='w100'>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}>
                              <label className='form-label'>
                                Email or Phone Number
                              </label>
                              {/* question mark icon with hover should show additional info */}

                              <span className='more-info'>
                                ?
                                <span className='more-info-tool-tip'>
                                  <h6>Why do we need this information? </h6>
                                  <p>
                                    We will use this information to contact you
                                    in case the direct deposit transaction
                                    fails.
                                  </p>
                                </span>
                              </span>
                            </div>
                            <div className='form-field'>
                              <input
                                type='text'
                                className='form-control'
                                placeholder='Please Enter Email or Phone Number'
                                value={contactInfo ?? ''}
                                onChange={(e) => setContactInfo(e.target.value)}
                                disabled={refundEmail ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                        <div className='form-row'>
                          <div className='w100'>
                            <label className='form-label'>Routing Number</label>
                            <div className='form-field'>
                              <input
                                type='number'
                                className='form-control'
                                value={routingNumber}
                                onChange={handleRoutingNumber}
                              />
                            </div>
                          </div>
                        </div>
                        <GridContainer>
                          <GridItem xs={6} sm={6} md={6}>
                            <div className='form-row'>
                              <div className='w100'>
                                <label className='form-label'>
                                  Account Number
                                </label>
                                <div className='form-field'>
                                  <input
                                    type='number'
                                    className='form-control'
                                    name='accountNumber'
                                    value={accountNumbers.accountNumber}
                                    onChange={handleAccountNumber}
                                  />
                                </div>
                              </div>
                            </div>
                          </GridItem>
                          <GridItem xs={6} sm={6} md={6}>
                            <div className='form-row'>
                              <div className='w100'>
                                <label className='form-label'>
                                  Confirm Account Number
                                </label>
                                <div className='form-field'>
                                  <input
                                    type='number'
                                    className='form-control'
                                    name='confirmAccountNumber'
                                    value={accountNumbers.confirmAccountNumber}
                                    onChange={handleAccountNumber}
                                  />
                                </div>
                              </div>
                            </div>
                          </GridItem>
                        </GridContainer>

                        {achMethod && achMethod === 'default' ? (
                          <div className='form-row'>
                            <div className='w100'>
                              <label className='form-label'>Bank Name</label>
                              <div className='form-field'>
                                <input
                                  type='text'
                                  className='form-control'
                                  value={bankName}
                                  onChange={handleBankName}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <div
                                className='custom-select'
                                style={{
                                  marginTop: '-5px',
                                }}>
                                <FormControl>
                                  <InputLabel id='demo-simple-select-label'>
                                    Bank Account Type
                                  </InputLabel>
                                  <Select
                                    labelId='demo-simple-select-label'
                                    id='demo-simple-select'
                                    value={bankAccountType}
                                    onChange={handleBankAccountType}
                                    className='full-width-elem'>
                                    <MenuItem value={'savings'}>
                                      Savings
                                    </MenuItem>
                                    <MenuItem value={'checking'}>
                                      Checking
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </GridItem>
                            {/* <GridItem xs={12} sm={12} md={6}>
                            <div
                              className='custom-select'
                              style={{
                                marginTop: '-10px',
                              }}>
                              <FormControl>
                                <InputLabel id='demo-simple-select-label'>
                                  ACH Type
                                </InputLabel>
                                <Select
                                  labelId='demo-simple-select-label'
                                  id='demo-simple-select'
                                  value={achType}
                                  onChange={handelAchType}
                                  className='full-width-elem'>
                                  <MenuItem value={'ach-credit-standard'}>
                                    ACH Standard
                                  </MenuItem>
                                  <MenuItem value={'ach-credit-same-day'}>
                                    ACH Same Day
                                  </MenuItem>
                                </Select>
                              </FormControl>
                            </div>
                          </GridItem> */}
                          </GridContainer>
                        )}

                        <div className='form-row'>
                          <div className='w50'>
                            <div className='form-btn'>
                              <Button
                                variant='contained'
                                disabled={loading}
                                type='submit'
                                className='form-save'>
                                Submit
                              </Button>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div className='mt-2'>
                        <button
                          variant='contained'
                          className='btn-green-outline'
                          onClick={handleClickPopup}>
                          <ArrowBackIosIcon />
                          Select Different Payment Option
                        </button>
                      </div>
                    </div>
                  </TabPanel>
                  {/*<!--<TabPanel value={value} index={3}>Cheque/DD</TabPanel>-->*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
