import axios from 'axios';
import { API_BASE_URL } from './config';
import { toast } from 'react-toastify';

const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem('otpForPayment');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    const message = response.data.message || response.data.msg;
    if (message) toast.success(message);
    return response;
  },
  (error) => {
    const message = error.response.data.message || error.response.data.msg;
    if (message) toast.error(message);
    //if the status is 401, redirect to login page

    if (error.response.status === 401) {
      const id = error.response.data.refundId;
      const type = error.response.data.type;
      navigate({
        to: type === 'email' ? '/payments/' + encode(id) : '/',
      });
    }

    return Promise.reject(error);
  },
);

export default axiosInstance;
