import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams, Redirect } from 'react-router-dom';
import { Base64 } from 'js-base64';
// or if you prefer no Base64 namespace
import { encode, decode } from 'js-base64';

import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { API_BASE_URL } from '../../config';
import axiosInstance from '../../axiosInstance';
import $ from 'jquery';

import logo from '../../assets/img/logo.png';
import verification from '../../assets/img/verification.svg';
import otp from '../../assets/img/motp.svg';
import tickmethod from '../../assets/img/tick-method.svg';
import charityIcon from '../../assets/img/charity-icon.svg';
import loaders from '../../assets/img/loader.svg';
import '../../assets/css/App.css';
import '../../assets/scss/global.scss';
import PaymentModal from 'components/Card/PaymentModal';
import { Box } from '@mui/system';
import EconsentModal from './EconsentModal';
import { toast } from 'react-toastify';

export default function Client() {
  // First Popup
  const [open, setOpen] = React.useState(false);
  const [redirects, setRedirects] = React.useState(false);
  const [redirect, setRedirect] = React.useState(false);
  const [epname, setEpname] = React.useState(null);
  const [otpone, setOtpone] = React.useState(null);
  const [otptwo, setOtptwo] = React.useState(null);
  const [otpthree, setOtpthree] = React.useState(null);
  const [otpfour, setOtpfour] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [errord, setErrord] = React.useState(null);
  const [otperror, setOtperror] = React.useState(null);
  const [logoimage, setLogoimage] = React.useState(null);
  const [msgotp, setMsgotp] = React.useState(null);
  const [open3, setOpen3] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const [charity, setCharity] = React.useState(false);
  const [charityid, setCharityid] = React.useState(null);

  const [Facilityid, setFacilityid] = React.useState(null);
  const [refundId, setRefundId] = React.useState(null);
  const [refundamount, setRefundamount] = React.useState(null);
  const [refundemail, setRefundemail] = React.useState(null);
  const [charityname, setCharityname] = React.useState(null);
  const [refundName, setRefundName] = React.useState(null);
  const [paymentselection, setPaymentselection] = React.useState(null);
  const [Facilityname, setFacilityname] = React.useState(null);
  const [redirecttocheque, setRedirecttocheque] = React.useState(null);
  const [paymentdetailid, setPaymentdetailid] = React.useState(null);
  const [
    redirecttoAmazonGiftCard,
    setRedirecttoAmazonGiftCard,
  ] = React.useState(null);
  const [checktrans, setChecktrans] = React.useState(false);

  const params = useParams();

  const handleInstant = () => {
    setRedirect(true);
  };

  const handleCheque = () => {
    setRedirecttocheque(true);
  };
  const handleAmazonGiftCard = () => {
    setRedirecttoAmazonGiftCard(true);
  };

  const handleDonation = () => {
    setCharity(true);
    setOpen3(false);
  };

  const handleEPName = (event) => {
    setEpname(event.target.value?.trim().toLowerCase());
  };

  const handleCharityid = (event) => {
    setCharityid(event.target.value);
    let slug = event.target.value;
    const otpForPayment = localStorage.getItem('otpForPayment');
    axiosInstance
      .post(API_BASE_URL + '/charitybyid/' + slug)
      .then((res) => {
        console.log('ssscharity', res && res.data);
        setCharityname(res && res.data && res.data.name);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleCancel = () => {
    setCharity(false);
    setOpen3(true);
  };

  const handleOtpone = (event) => {
    setOtpone(event.target.value);
    $('#two').focus();
  };

  const testpayout = () => {
    axiosInstance
      .post(API_BASE_URL + '/paypal_payout')
      .then((response) => {
        return response;
      })
      .then((res) => {
        console.log('sss', res);
        //this.setState({redirect:true})
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleClickOpen = (e) => {
    e.preventDefault();
    setLoader(true);

    if (epname == null) {
      setError('Please enter email.');
      setLoader(false);
    } else if (
      !epname.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      )
    ) {
      toast.error('Please enter valid email address.');
    } else {
      const formData = new FormData();

      formData.append('keyword', epname.toLowerCase());
      formData.append('Facilityname', Facilityname);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      let slug = params && params.id;
      const formDataN = new FormData();
      formDataN.append('keyword', epname.trim().toLowerCase());
      console.log(slug);

      axiosInstance
        .post(API_BASE_URL + '/generateotp/' + slug, formData, {
          headers: headers,
        })
        .then((res) => {
          if (res.status == 200) {
            setLoader(false);
            setOpen2(true);
          } else {
            setLoader(false);
            setError(res.data && res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    //setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const resentotp = () => {
    setLoader(true);
    const formData = new FormData();

    formData.append('keyword', epname.toLowerCase());
    formData.append('Facilityname', Facilityname);

    const headers = {
      'Content-Type': 'multipart/form-data',
    };
    //console.log("params",params.id)
    let slug = params && params.id;
    console.log(slug);
    axiosInstance
      .post(API_BASE_URL + '/generateotp/' + slug, formData, {
        headers: headers,
      })
      .then((response) => {
        return response;
      })
      .then((res) => {
        console.log('sss', res);
        //this.setState({redirect:true})
        if (res.status == 200) {
          setMsgotp('Otp has been sent at email');
          setLoader(false);
        } else {
          setError(res.data && res.data.msg);
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //Second Popup
  const [open2, setOpen2] = React.useState(false);

  const handleClickOpen2 = () => {
    setOpen2(true);
  };
  const handleDonate = () => {
    setLoader(true);

    //console.log("Charityid",charityid);
    if (charityid == null) {
      setErrord('Charity cannot be empty.');
    } else {
      const formData = new FormData();
      formData.append('facility_id', Facilityid);
      formData.append('charity_id', charityid);
      formData.append('refundamount', refundamount);
      formData.append('refundemail', refundemail);
      formData.append('charityname', charityname);
      formData.append('refundName', refundName);

      //formData.append('Facilityid', Facilityid);

      axiosInstance
        .post('/add-donation', formData)
        .then((response) => {
          return response;
        })
        .then((res) => {
          console.log('donation response', res);
          if (res && res.data && res.data.error) {
            setErrord(res && res.data && res.data.msg);
          } else {
            //setRedirects(true);
            setPaymentdetailid(
              res && res.data && res.data.resultdata && res.data.resultdata.id,
            );
            setLoader(false);
            setRedirects(true);
          }
          //this.setState({redirect:true})
          /*	if(res.status==200){
						setOpen2(true);
					} else {
						setError(res.data && res.data.msg);
					}*/
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleClose2 = (e) => {
    e.preventDefault();
    setLoader(true);
    if (!otpone) {
      setOtperror('Your One Time Password cannot be blank');
    } else {
      const formData = new FormData();

      let otpdata = otpone;

      formData.append('otpdata', otpdata);

      const headers = {
        'Content-Type': 'multipart/form-data',
      };
      let slug = params && params.id;
      axiosInstance
        .post('/matchotp/' + slug, formData, {
          headers: headers,
        })
        .then((res) => {
          sessionStorage.setItem('otpForPayment', res.data.token);
          setOpen2(false);
          setOpen3(true);
          setFlag(true);
          setLoader(false);
        })
        .catch((err) => {
          setOtperror(err.response?.data?.msg);
          setLoader(false);
          console.log(err);
        });
    }
    //setOpen2(false);
  };
  const [isEconsentAgreed, setIsEconsentAgreed] = useState(null);
  const [econsent, setEconsent] = useState({});
  useEffect(async () => {
    if (!open3) return;
    const res = await axiosInstance.get('refundee-econsent-details');

    setEconsent(res.data?.econsent);
    setIsEconsentAgreed(res.data?.isEconsentAgreed);
  }, [open3]);

  const handleClose3 = () => {
    //setOpen3(false);
    //setCharity(true);
    //setOpen3(false);
  };

  const handleClose4 = () => {
    //setOpen3(false);
    //setOpen3(true);
    //setFlag(true);
  };
  const closex = () => {
    setChecktrans(false);
  };

  // to switch between text

  const handleAltText = () => {
    setFlag(true);
  };
  const agreeEconsent = async () => {
    await axiosInstance.get('/agree-econsent');
    setIsEconsentAgreed(true);
  };
  console.log('open3', Facilityname);
  return (
    <div>
      {redirecttocheque && (
        <Redirect to={'/client/chequedetail/' + params.id} />
      )}
      <div className='submit-mail-wrapper welcome-banner'>
        <header className='s-header'>
          <div className='container'>
            <div className='s-header_inner'>
              <div className='inner-brand'>
                <img src={logo} alt='' />
              </div>
              <div className='site-links'>
                <ul className='n-links'>
                  {/* <li>
                    <Link to='/' className='n-link'>
                      FAQ
                    </Link>
                  </li> */}
                  <li>
                    <a
                      href='https://www.clientpaydirect.com/contact'
                      className='n-link'
                      target='_blank'>
                      Contact us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </header>
        <div className='main-content'>
          <div className='container'>
            <div className='email-box-wrapper'>
              <div className='email-box'>
                {/* <div className="email-box-header" style={{ float: 'right' }}>
									{logoimage &&
										<img src={logoimage} width="142" height="65" alt="" />
									}
								</div> */}
                {/* {loader && <div className="loader"><img src={loaders}></img></div>} */}
                <div className='email-box-header'>
                  <h1>
                    <strong>Welcome!</strong>
                  </h1>
                </div>
                <div className='email-box-body less-spacing'>
                  <p>
                    You are just a couple of steps away from instant access to
                    your funds.
                  </p>
                  {error && <p style={{ color: '#ff0000' }}>{error}</p>}
                  <div className='submit-box'>
                    <form onSubmit={handleClickOpen}>
                      <input
                        type='email'
                        name='epname'
                        placeholder='Enter Email'
                        onChange={handleEPName}
                      />
                      <Button
                        variant='contained'
                        className='btn-submit'
                        onClick={handleClickOpen}>
                        Submit
                      </Button>
                    </form>
                  </div>
                </div>
                {/* <div className='f-separator'>
                  <span className='option-sep'>OR</span>
                </div> */}
                {/* <div className='btn-sec'>
                  <Button
                    variant='contained'
                    className='btn-solid btn-solid-auto-h'
                    onClick={handleCheque}>
                    Skip and send my funds via check
                  </Button>
                </div> */}
                <p className='addn-text'>
                  By Proceeding, I agree to Client Pay Direct's{' '}
                  <a
                    href='/privacypolicy.pdf'
                    target='_blank'
                    className='green-link'>
                    Privacy Notice
                  </a>{' '}
                  .
                </p>
                {/* <div className="email-box-footer">
						<p>Questions or concerns? Please don’t hesitate to contact us here.</p>
					</div> */}
              </div>
            </div>
          </div>
        </div>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby='form-dialog-title'>
          <div className='dialog-body'>
            <div className='dialog-icon'>
              <span>
                <img src={verification} alt='' />
              </span>
            </div>
            <h3 className='dialog-title'>Your Verification</h3>
            <p className='dialog-desc'>
              Please enter your Email Address/Phone Number to
              <br /> receive a verification code.
            </p>
            <div>
              <div className='dialog-field'>
                <input
                  type='text'
                  className='d-field'
                  placeholder='Email/Phone Number'
                />
              </div>
              <div className='dialog-action'>
                <Button
                  variant='contained'
                  className='btn-solid'
                  onClick={() => {
                    handleClose();
                    handleClickOpen2();
                  }}>
                  Submit
                </Button>
              </div>
              <div className='dialog-action'>
                <Button
                  variant='contained'
                  className='btn-solid'
                  onClick={() => {
                    testpayout();
                  }}>
                  Payout
                </Button>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={checktrans}
          scroll='body'
          aria-labelledby='form-dialog-title'
          className='otp-dialog'>
          <div className='dialog-head'>
            <span className='closex'>
              <a
                onClick={closex}
                style={{ color: '#ffffff', cursor: 'pointer' }}>
                x
              </a>
            </span>
            <div className='dialog-icon'>
              <span>
                <img src={otp} alt='' />
              </span>
            </div>
            <h3 className='dialog-title'>
              We have already processed your refund, and it's on the way!
            </h3>
            {/* <p className="dialog-desc">Your one time password has been sent <br />to your email. Please verify below.</p> */}
          </div>
          <div className='dialog-body'>
            {otperror && <p style={{ color: '#ff0000' }}>{otperror}</p>}
            <div>
              <div className='missed-otp'>
                We have checked our records and it looks like we have processed
                your refund.Please contact us with any questions at{' '}
                <a href='mailto:help@clientpaydirect.com'>
                  help@clientpaydirect.com
                </a>
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          open={open2}
          onClose={handleClose2}
          scroll='body'
          aria-labelledby='form-dialog-title'
          className='otp-dialog'>
          <div className='dialog-head'>
            <div className='dialog-icon'>
              <span>
                <img src={otp} alt='' />
              </span>
            </div>
            {/* <h3 className='dialog-title'>One Time Password</h3> */}
            <p
              style={{
                fontSize: '16px',
                maxWidth: '350px',
                fontWeight: 'bold',
              }}
              className='dialog-desc'>
              Your one time password has been sent to you.
              <br /> <br /> Do not close this window. Check your email in a new
              window, get your 6 digit password and enter it below.
            </p>
          </div>
          <div className='dialog-body'>
            {otperror && <p style={{ color: '#ff0000' }}>{otperror}</p>}
            <form onSubmit={handleClose2}>
              <div>
                <div className='multi-field'>
                  <span className='digit'>
                    <input
                      type='number'
                      maxLength='4'
                      className='d-field'
                      onChange={handleOtpone}
                      name='one'
                      id='one'
                    />
                  </span>
                </div>
                <div className='missed-otp'>
                  {/* {msgotp && <p style={{ color: 'green' }}>{msgotp}</p>} */}
                  <a
                    style={{ cursor: 'pointer' }}
                    className='resend-otp'
                    onClick={resentotp}>
                    Resend One Time Password
                  </a>
                </div>
                <div className='dialog-action'>
                  <Button
                    variant='contained'
                    className='btn-solid'
                    type='submit'>
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </Dialog>
        <EconsentModal
          agreeEconsent={agreeEconsent}
          econsent={econsent}
          econsentAgreed={isEconsentAgreed}
        />
        <PaymentModal
          charity={charity}
          refundamount={refundamount}
          handleDonation={handleDonation}
          open3={open3}
          handleClose={handleClose3}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  );
}
