import React, { useEffect, useState } from 'react';
import { Modal, Box } from '@mui/material';
import Button from 'components/CustomButtons/Button';
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import CustomInput from 'components/CustomInput/CustomInput';
import { modalStyle } from 'views/Clients/Claim';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { PictureAsPdf, Close } from '@material-ui/icons';
import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from '../../config';

export default function UpdateRefundModal({
  currentInfo,
  originalInfo,
  modalErr,
  setModalErr,
  onInputChange,
  setCurrentInfo,
  setOriginalInfo,
  role,
  getRefunds,
  payments,
  currentCheckRefund,
  setCurrentCheckRefund,
}) {
  const [addFiles, setAddFiles] = useState([]);
  const [deletedFiles, setDeletedFiles] = useState([]);

  useEffect(async () => {
    const result = await axios.get(
      API_BASE_URL +
        `/refund-attachments${role === 'admin' ? '-admin' : ''}/` +
        currentInfo.id,
    );

    const files = result.data?.map((file) => ({
      originalFileName: file.original_file_name,
      awsKey: file.aws_key,
      uploadedBy: file.uploaded_by,
      uploadedAt: file.uploaded_at,
      local: false,
    }));

    setAddFiles(files);
  }, []);

  const updateInfo = (e, currentInfo, originalInfo) => {
    e.preventDefault();

    const newInfo = {
      first_name: currentInfo.first_name?.trim(),
      last_name: currentInfo.last_name?.trim(),
      email: currentInfo.email?.trim(),
      refund_to_address_line1: currentInfo.refund_to_address_line1?.trim(),
      refund_to_address_line2: currentInfo.refund_to_address_line2?.trim(),
      refund_to_city: currentInfo.refund_to_city?.trim(),
      refund_to_state: currentInfo.refund_to_state?.trim(),
      refund_to_zip: currentInfo.refund_to_zip?.trim(),
    };

    const oldInfo = originalInfo;

    //check if any info has changed
    let changed = false;
    for (let key in newInfo) {
      if (newInfo[key] != oldInfo[key]?.trim()) {
        changed = true;
        break;
      }
    }
    if (changed && !currentInfo.comment) {
      toast.error('Please enter a comment', { autoClose: 2000 });
      return;
    }

    const formData = new FormData();
    formData.append('oldInfo', JSON.stringify(oldInfo));
    formData.append('newInfo', JSON.stringify(newInfo));
    formData.append('comment', currentInfo.comment);
    formData.append(
      'resendCommunication',
      currentInfo.resendCommunication ?? 'false',
    );
    formData.append('id', currentInfo.id);
    formData.append('changed', changed);
    formData.append('deletedFiles', JSON.stringify(deletedFiles));

    addFiles.forEach((file) => {
      if (file.local) formData.append('files', file.file);
    });

    axios
      .post(
        API_BASE_URL +
          `/update_refund_info${role === 'admin' ? '_admin' : ''}/` +
          currentInfo.id,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        },
      )
      .then((res) => {
        if (currentCheckRefund)
          setCurrentCheckRefund({
            ...currentCheckRefund,
            ...newInfo,
          });
        setCurrentInfo(null);
        setOriginalInfo(null);
        getRefunds(true, payments.length);
      })
      .catch((err) => {
        setModalErr(err.response?.data?.message);
      });
  };

  return (
    <Modal
      open={currentInfo ? true : false}
      onClose={() => {
        setCurrentInfo(null);
        setOriginalInfo(null);
      }}>
      <form onSubmit={(e) => updateInfo(e, currentInfo, originalInfo)}>
        <Box
          sx={modalStyle}
          className='makeStyles-card-96'
          noValidate
          autoComplete='off'>
          <CardHeader style={{ transform: 'translateY(-50%)' }} color='primary'>
            <h4>Update Refund Info </h4>
          </CardHeader>
          <CardBody style={{ marginTop: '-20px' }}>
            {modalErr && (
              <p
                style={{
                  color: 'red',
                  padding: '0',
                  margin: 0,
                  fontSize: '15px',
                }}>
                {modalErr}
              </p>
            )}
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='email'
                  name='email'
                  labelText='Email'
                  value={currentInfo ? currentInfo['email'] : ''}
                  autoComplete='off'
                  onChange={onInputChange}
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='first_name'
                  labelText='First Name'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['first_name'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='last_name'
                  labelText='Last Name'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['last_name'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_address_line1'
                  labelText='Address Line 1'
                  onChange={onInputChange}
                  value={
                    currentInfo ? currentInfo['refund_to_address_line1'] : ''
                  }
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_address_line2'
                  labelText='Address Line 2'
                  onChange={onInputChange}
                  value={
                    currentInfo ? currentInfo['refund_to_address_line2'] : ''
                  }
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_city'
                  labelText='City'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['refund_to_city'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_state'
                  labelText='State'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['refund_to_state'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='refund_to_zip'
                  labelText='Zip Code'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['refund_to_zip'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
              {currentInfo.refund_type === 'insurance' &&
                currentInfo.allow_refund_attachments === true && (
                  <GridItem xs={12} sm={12} md={12}>
                    <ManageFiles
                      addFiles={addFiles}
                      setAddFiles={setAddFiles}
                      currentInfo={currentInfo}
                      setDeletedFiles={setDeletedFiles}
                      deletedFiles={deletedFiles}
                    />
                  </GridItem>
                )}
              <GridItem xs={12} sm={12} md={6}>
                <CustomInput
                  type='text'
                  name='comment'
                  labelText='Comment'
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['comment'] : ''}
                  autoComplete='off'
                  formControlProps={{
                    fullWidth: true,
                  }}
                />
              </GridItem>
            </GridContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                }}>
                <input
                  type='checkbox'
                  name='resendCommunication'
                  labelText=''
                  onChange={onInputChange}
                  value={currentInfo ? currentInfo['resendCommunication'] : ''}
                />
                <label>
                  Resend Communication{' '}
                  <small>(If there is no email a letter is sent)</small>
                </label>
              </div>
              <Button variant='contained' color='rose' type='submit'>
                Update
              </Button>
            </div>
          </CardBody>
        </Box>
      </form>
    </Modal>
  );
}

const ManageFiles = ({
  addFiles,
  setAddFiles,
  setDeletedFiles,
  deletedFiles,
}) => {
  const openPdf = (url) => {
    window.open(url, '_blank');
  };

  const handleFileUpload = (event) => {
    const uploadedFiles = Array.from(event.target.files).map((file) => ({
      originalFileName: file.name,
      fileUrl: URL.createObjectURL(file),
      fileType: file.type,
      uploadedBy: 'You',
      file: file,
      local: true,
    }));
    setAddFiles((prevFiles) => {
      return [...prevFiles, ...uploadedFiles];
    });
  };

  const handleFileRemove = (index) => {
    const fileToRemove = addFiles[index];
    if (fileToRemove.local === true) {
      return setAddFiles((prevFiles) => {
        return prevFiles.filter((_, i) => i !== index);
      });
    }

    // also scheck aws key duplicates
    return setDeletedFiles((prevDeleted) => {
      const newDeleted = [...prevDeleted, fileToRemove.awsKey];

      const deletedSet = new Set(newDeleted);
      return [...deletedSet];
    });
  };

  return (
    <div
      style={{
        marginTop: '20px',
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        padding: '10px 0',
      }}>
      {addFiles.map((file, index) => {
        return (
          (!deletedFiles.includes(file.awsKey) || file.local) && (
            <div
              title={
                'File Name: ' +
                file.originalFileName +
                '\n' +
                ('Uploaded By: ' + file.uploadedBy)
              }
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                justifyContent: 'space-between',
                padding: '10px',
                background: '#36454F',
                borderRadius: '5px',
                fontSize: '14px',
                color: 'white',
                position: 'relative',
                cursor: 'pointer',
              }}
              key={index}
              onClick={() =>
                openPdf(
                  file.fileUrl ??
                    API_BASE_URL +
                      '/getFile_facility?filePath=' +
                      file.awsKey +
                      '&view=inline',
                )
              }>
              <div>
                {file.originalFileName.length > 25
                  ? `${file.originalFileName.substring(0, 25)}...`
                  : file.originalFileName}
              </div>
              <PictureAsPdf />
              <div
                title=''
                style={{
                  background: '#e91e63',
                  zIndex: 10,
                  position: 'absolute',
                  top: '-10px',
                  right: '-10px',
                  cursor: 'pointer',
                  height: '22px',
                  width: '22px',
                  borderRadius: '50%',
                  padding: '2px',
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleFileRemove(index);
                }}>
                <Close style={{ maxWidth: '100%', maxHeight: '100%' }} />
              </div>
            </div>
          )
        );
      })}
      <div
        style={{
          height: '40px',
        }}>
        <input
          type='file'
          accept='application/pdf'
          multiple
          style={{ display: 'none' }}
          id='file-upload'
          onChange={handleFileUpload}
        />
        <label htmlFor='file-upload'>
          <Button color='primary' component='span'>
            Upload Files
          </Button>
        </label>
      </div>
    </div>
  );
};
